var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Card, FlexBox } from 'components';
import VerticalProgress from 'components/VerticalProgress';
var getAverageScore = function (storieVotes) {
    var normalizedVotes = storieVotes.map(function (vote) {
        return typeof vote.value === 'string' ? __assign(__assign({}, vote), { value: vote.value.replace('½', '0.5') }) : vote;
    });
    var votes = normalizedVotes.filter(function (vote) { return Number(vote.value); });
    var votesSum = votes.reduce(function (acc, vote) { return acc + Number(vote.value); }, 0);
    return Math.floor(votesSum / votes.length * 100) / 100 || 0;
};
var GameFieldResults = function (_a) {
    var votedUsers = _a.votedUsers, isVotingStarted = _a.isVotingStarted;
    var _b = React.useState(0), averageScore = _b[0], setAverageScore = _b[1];
    var _c = React.useState([]), valueStats = _c[0], setValueStats = _c[1];
    React.useEffect(function () {
        if (!isVotingStarted && (votedUsers === null || votedUsers === void 0 ? void 0 : votedUsers.length) > 0) {
            var calcAverageScore = getAverageScore(votedUsers);
            setAverageScore(calcAverageScore);
            var uniqueVoteValues = Array.from(new Set(votedUsers.map(function (vote) { return vote.value; })));
            uniqueVoteValues.forEach(function (value) {
                var _a;
                var votesCount = (_a = votedUsers.filter(function (vote) { return value === vote.value; })) === null || _a === void 0 ? void 0 : _a.length;
                setValueStats(function (prev) { return (__spreadArray(__spreadArray([], prev), [
                    {
                        value: value,
                        count: votesCount,
                    },
                ])); });
            });
        }
    }, [JSON.stringify(votedUsers), isVotingStarted]);
    React.useEffect(function () {
        if (isVotingStarted) {
            setAverageScore(0);
            setValueStats([]);
        }
    }, [isVotingStarted]);
    var highlighedVotes = React.useMemo(function () {
        var maxCountOfVotes = valueStats.reduce(function (acc, current) { return (acc > current.count) ? acc : current.count; }, 0);
        return valueStats.filter(function (vote) { return vote.count === maxCountOfVotes; });
    }, [valueStats]);
    if (isVotingStarted || !valueStats.length) {
        return null;
    }
    return (React.createElement(FlexBox, { alignItems: "center", flexDirection: "column" },
        React.createElement(Box, null,
            React.createElement(Typography, { display: "inline" }, "Average score:\u00A0"),
            React.createElement(Typography, { display: "inline", variant: "h6" }, averageScore)),
        React.createElement(FlexBox, null, valueStats && valueStats.map(function (vote) {
            var totalVotesCount = votedUsers.length;
            var progress = Math.floor(100 / totalVotesCount * vote.count);
            var isHighlighted = highlighedVotes.includes(vote);
            return (React.createElement(FlexBox, { key: vote.value, flexDirection: "column", alignItems: "center", padding: 1 },
                React.createElement(VerticalProgress, { progress: progress, highlighted: isHighlighted }),
                React.createElement(Box, { padding: .5 },
                    React.createElement(Card, { selected: false }, vote.value)),
                React.createElement(Typography, null,
                    vote.count,
                    "\u00A0",
                    vote.count === 1 ? 'vote' : 'votes')));
        }))));
};
export default GameFieldResults;
