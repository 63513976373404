import React from 'react';
import { Button, Container, List, ListItem, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { FlexBox } from 'components';
import { useGameIdSelector } from 'hooks';
import GameStoriesQuery from './GameStoriesQuery.graphql';
import ChangeGameStatusMutation from './ChangeGameStatusMutation.graphql';
import { storieValidationSchema } from './validation';
import { gameStoriesStyles } from './styles';
var INITIAL_VALUES = {
    storieId: '',
};
var Stories = function (_a) {
    var isGameOwner = _a.isGameOwner, currentVotingStorie = _a.currentVotingStorie, isVotingStarted = _a.isVotingStarted;
    var gameId = useGameIdSelector();
    var styles = gameStoriesStyles();
    var _b = React.useState(''), selectedStorie = _b[0], setSelectedStorie = _b[1];
    var _c = useQuery(GameStoriesQuery, {
        variables: {
            gameId: gameId,
        },
    }), _d = _c.data, _e = _d === void 0 ? {} : _d, _f = _e.stories, stories = _f === void 0 ? [] : _f, loading = _c.loading;
    var _g = useForm({
        mode: 'onChange',
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(storieValidationSchema),
    }), handleSubmit = _g.handleSubmit, setValue = _g.setValue, isValid = _g.formState.isValid;
    var changeGameStatus = useMutation(ChangeGameStatusMutation)[0];
    var onSubmit = function (_a) {
        var storieId = _a.storieId;
        changeGameStatus({
            variables: {
                data: {
                    gameId: gameId,
                    storieId: storieId,
                },
            },
        });
    };
    React.useEffect(function () {
        setValue('storieId', selectedStorie, { shouldValidate: true });
    }, [selectedStorie, setValue]);
    React.useEffect(function () {
        setSelectedStorie(currentVotingStorie);
    }, [currentVotingStorie]);
    return (React.createElement(FlexBox, { flexDirection: "column", alignItems: "center", className: styles.root },
        React.createElement(Typography, { variant: "subtitle2" }, "Stories list"),
        React.createElement(Container, { maxWidth: "sm", component: "form", onSubmit: handleSubmit(onSubmit) },
            React.createElement(List, null, (stories && !loading) && stories.map(function (storie) {
                var _a;
                return React.createElement(ListItem, { key: storie.id, button: true, onClick: function () { return setSelectedStorie(storie.id); }, selected: isVotingStarted ? storie.id === currentVotingStorie : selectedStorie === storie.id, disabled: isVotingStarted && isGameOwner },
                    React.createElement(Typography, { variant: "body2", className: cn((_a = {}, _a[styles.completedStory] = storie.isVoted, _a)) }, storie.storieName));
            })),
            isGameOwner && (React.createElement(Button, { variant: "contained", color: isVotingStarted ? 'secondary' : 'primary', type: "submit", fullWidth: true, disabled: !isValid }, isVotingStarted ? 'Show cards' : 'Start new round')))));
};
export default Stories;
