var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split, } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { useGlobalStyles } from 'hooks';
import { getValue } from 'utils';
import { renderRoutes, routes } from 'global/router';
import { AuthProvider } from '../Auth';
var getProtocol = {
    get http() {
        return window.location.protocol === 'https:' ? 'https' : 'http';
    },
    get ws() {
        return window.location.protocol === 'https:' ? 'wss' : 'ws';
    },
};
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    var token = getValue('scr-poker-token');
    return {
        headers: __assign(__assign({}, headers), { authorization: token ? "Bearer " + token : '' }),
    };
});
var httpLink = createHttpLink({
    uri: getProtocol.http + "://" + window.location.hostname + ":" + window.location.port + "/graphql",
});
var wsLink = function () {
    var token = getValue('scr-poker-token');
    return new WebSocketLink({
        uri: getProtocol.ws + "://" + window.location.hostname + ":" + window.location.port + "/graphql",
        options: {
            reconnect: true,
            connectionParams: {
                Authorization: "Bearer " + token,
            },
        },
    });
};
var splitLink = split(function (_a) {
    var query = _a.query;
    var definition = getMainDefinition(query);
    return (definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription');
}, wsLink(), authLink.concat(httpLink));
var client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});
var renderedRoutes = renderRoutes(routes);
var Root = function () {
    useGlobalStyles();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(AuthProvider, null,
            React.createElement(BrowserRouter, null, renderedRoutes))));
};
export default Root;
