import React from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { FlexBox } from 'components';
import { useGameIdSelector } from 'hooks';
import routes from 'global/router/routes';
import GameInfoQuery from 'pages/game/GameInfoQuery.graphql';
import { headerStyles } from './styles';
import MeQuery from '../Auth/MeQuery.graphql';
var Header = function () {
    var _a;
    var pathname = useLocation().pathname;
    var gameId = useGameIdSelector();
    var styles = headerStyles();
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = React.useMemo(function () { return Boolean(anchorEl); }, [anchorEl]);
    var handleClose = React.useCallback(function () {
        setAnchorEl(null);
    }, []);
    var handleMenuOpen = React.useCallback(function (event) {
        setAnchorEl(event.currentTarget);
    }, []);
    var client = useApolloClient();
    var _c = client.readQuery({
        query: MeQuery,
    }).me.username, username = _c === void 0 ? '' : _c;
    var gameInfoQuery = client.readQuery({
        query: GameInfoQuery,
        variables: {
            gameId: gameId,
        },
    });
    var title = React.useMemo(function () {
        var _a, _b;
        var basicTitle = ((_a = routes.find(function (route) { return route.path === pathname; })) === null || _a === void 0 ? void 0 : _a.title) || '';
        return gameId ? (_b = gameInfoQuery === null || gameInfoQuery === void 0 ? void 0 : gameInfoQuery.gameInfo) === null || _b === void 0 ? void 0 : _b.gameName : basicTitle;
    }, [pathname, gameId, (_a = gameInfoQuery === null || gameInfoQuery === void 0 ? void 0 : gameInfoQuery.gameInfo) === null || _a === void 0 ? void 0 : _a.gameName]);
    return (React.createElement(AppBar, { position: "static", className: styles.header },
        React.createElement(Toolbar, null,
            React.createElement(Typography, { variant: "h6", className: styles.title }, title),
            React.createElement(FlexBox, { alignItems: "center" },
                React.createElement(Typography, { className: styles.username }, username),
                React.createElement(IconButton, { "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", color: "inherit", onClick: handleMenuOpen },
                    React.createElement(AccountCircle, null))))));
};
export default Header;
