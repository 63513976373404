import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Container, Paper, Typography, Link, Button, Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import QueryString from 'query-string';
import { FlexBox, TextField } from 'components';
import { setValue } from 'utils';
import { authStyles } from './styles';
import { authValidationSchema } from './validation';
import BasicCreateUserMutation from './BasicAuthMutation.graphql';
import { AUTH } from '../../constants';
var INITIAL_VALUES = {
    username: '',
};
var Auth = function () {
    var styles = authStyles();
    var _a = useForm({
        mode: 'onChange',
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(authValidationSchema),
    }), handleSubmit = _a.handleSubmit, control = _a.control, _b = _a.formState, errors = _b.errors, isValid = _b.isValid;
    var search = useLocation().search;
    var createUser = useMutation(BasicCreateUserMutation, {
        onCompleted: function (_a) {
            var userToken = _a.createUser;
            var redirect = QueryString.parse(search).redirect;
            setValue('scr-poker-token', userToken);
            window.location.href = String(redirect);
        },
    })[0];
    var onSubmit = React.useCallback(function (_a) {
        var username = _a.username;
        return createUser({ variables: { username: username } });
    }, [createUser]);
    var inputRef = React.useRef(null);
    React.useEffect(function () {
        var _a;
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (React.createElement(Container, { maxWidth: "sm", className: styles.root },
        React.createElement(Paper, { className: styles.paper },
            React.createElement(Container, { maxWidth: "xs", component: "form", onSubmit: handleSubmit(onSubmit) },
                React.createElement(FlexBox, { alignItems: "center", flexDirection: "column" },
                    React.createElement(Typography, { variant: "h1", align: "center" }, "Introduce yourself"),
                    React.createElement(Box, { alignItems: "center", flexDirection: "column", className: styles.form },
                        React.createElement(TextField, { name: "username", label: "Your name", variant: "outlined", fullWidth: true, className: styles.input, inputRef: inputRef, inputProps: { maxLength: AUTH.MAX_LENGTH }, control: control, errors: errors }),
                        React.createElement(Button, { variant: "contained", color: "primary", type: "submit", disabled: !isValid, fullWidth: true }, "Login")),
                    React.createElement(Typography, { variant: "body2" },
                        "Also you can ",
                        React.createElement(Link, null, "login"),
                        " or ",
                        React.createElement(Link, null, "sign up")))))));
};
export default Auth;
