import React from 'react';
import { useMutation } from '@apollo/client';
import { Card, FlexBox } from 'components';
import { useGameIdSelector } from 'hooks';
import { gameFieldControlsStyles } from './styles';
import GameFieldControlsVoteMutation from './GameFieldControlsVoteMutation.graphql';
var GameFieldControls = function (_a) {
    var cards = _a.cards, storieId = _a.storieId, isVotingStarted = _a.isVotingStarted, votedCard = _a.votedCard;
    var styles = gameFieldControlsStyles();
    var gameId = useGameIdSelector();
    var _b = React.useState(null), selectedCard = _b[0], setSelectedCard = _b[1];
    var vote = useMutation(GameFieldControlsVoteMutation)[0];
    var onCardSelected = React.useCallback(function (value) {
        setSelectedCard(function (prev) { return prev === value ? null : value; });
        vote({
            variables: {
                data: {
                    gameId: gameId,
                    storieId: storieId,
                    value: value,
                },
            },
        });
    }, [gameId, storieId]);
    React.useEffect(function () {
        if (isVotingStarted) {
            setSelectedCard(votedCard);
        }
    }, [votedCard, isVotingStarted]);
    React.useEffect(function () {
        if (!isVotingStarted) {
            setSelectedCard(null);
        }
    }, [isVotingStarted]);
    return (React.createElement(FlexBox, { className: styles.container }, cards && cards.map(function (card) {
        var isSelected = card === selectedCard;
        return (React.createElement(FlexBox, { key: card, paddingX: 0.5, paddingY: 0.5 },
            React.createElement(Card, { disabled: !isVotingStarted, selected: isSelected, onClick: function () { return onCardSelected(card); } }, card)));
    })));
};
export default GameFieldControls;
