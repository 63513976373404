import React from 'react';
import useAuth from 'hooks/useAuth';
import RedirectRoute from './RedirectRoute';
import useRedirectedPath from './useRedirectedPath';
var PublicRoute = function (props) {
    var isAuth = useAuth().isAuth;
    var redirectedPath = useRedirectedPath();
    return React.createElement(RedirectRoute, { routeProps: props, redirect: redirectedPath, condition: !isAuth });
};
export default PublicRoute;
