import React from 'react';
import { Header } from 'containers';
import { FlexBox } from 'components';
var DashboardStruct = function (_a) {
    var children = _a.children;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(FlexBox, { marginTop: 8 }, children)));
};
export default DashboardStruct;
