import React from 'react';
import { useQuery } from '@apollo/client';
import MeQuery from './MeQuery.graphql';
export var AuthContext = React.createContext({
    isAuth: false,
});
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useQuery(MeQuery, {
        notifyOnNetworkStatusChange: true,
    }), data = _b.data, loading = _b.loading;
    var _c = React.useState(false), isAuth = _c[0], setAuth = _c[1];
    var _d = React.useState(true), isLoading = _d[0], setLoading = _d[1];
    React.useEffect(function () {
        setLoading(loading);
        setAuth(!!data);
    }, [data, loading, isAuth]);
    if (isLoading) {
        return null;
    }
    return (React.createElement(AuthContext.Provider, { value: {
            isAuth: isAuth,
        } }, children));
};
