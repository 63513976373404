var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
var RedirectRoute = function (_a) {
    var condition = _a.condition, redirect = _a.redirect, routeProps = _a.routeProps;
    var Comp = routeProps.component, restProps = __rest(routeProps, ["component"]);
    return condition ? (React.createElement(Route, __assign({}, restProps, { render: function (props) { return React.createElement(Comp, __assign({}, props)); } }))) : (React.createElement(Redirect, { to: !routeProps.location
            ? redirect
            : {
                pathname: redirect,
                state: {
                    from: routeProps.location,
                },
            } }));
};
export default RedirectRoute;
