import useAuth from 'hooks/useAuth';
import React from 'react';
import RedirectRoute from './RedirectRoute';
import useRedirectedPath from './useRedirectedPath';
var PrivateRoute = function (props) {
    var isAuth = useAuth().isAuth;
    var redirectedPath = useRedirectedPath();
    return React.createElement(RedirectRoute, { routeProps: props, redirect: "/?redirect=" + redirectedPath, condition: isAuth });
};
export default PrivateRoute;
