var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
export var addIndexPath = function (path) { return "(" + path + "|/)"; };
export var renderRoutes = function (routes) {
    return routes.map(function (_a) {
        var path = _a.path, component = _a.component, defaultRoute = _a.defaultRoute, exact = _a.exact, rest = __rest(_a, ["path", "component", "defaultRoute", "exact"]);
        if (!rest.private) {
            return (React.createElement(PublicRoute, { key: path, component: component, path: defaultRoute ? addIndexPath(path) : path, exact: exact }));
        }
        if (rest.private) {
            return (React.createElement(PrivateRoute, { key: path, component: component, path: defaultRoute ? addIndexPath(path) : path, exact: exact }));
        }
        return (React.createElement(Route, { key: path, component: component, path: defaultRoute ? addIndexPath(path) : path, exact: exact }));
    });
};
