import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { FlexBox } from 'components';
import { useGameIdSelector } from 'hooks';
import { gameFieldStyles } from './styles';
import GameFieldOnlineList from './GameFieldOnlineList';
import GameFieldControls from './GameFieldControls';
import GameFieldResults from './GameFieldResults';
import GameInfoQuery from '../GameInfoQuery.graphql';
var GameField = function (_a) {
    var title = _a.title;
    var styles = gameFieldStyles();
    var gameId = useGameIdSelector();
    var _b = useQuery(GameInfoQuery, {
        variables: {
            gameId: gameId,
        },
    }).data, _c = _b === void 0 ? {} : _b, _d = _c.gameInfo, gameInfo = _d === void 0 ? {} : _d;
    var _e = gameInfo.status, _f = _e === void 0 ? {} : _e, _g = _f.votingStorieId, votingStorieId = _g === void 0 ? null : _g, _h = _f.isVotingStarted, isVotingStarted = _h === void 0 ? false : _h, _j = _f.votedUsers, votedUsers = _j === void 0 ? [] : _j, cards = gameInfo.cards, votedScore = gameInfo.votedScore;
    return (React.createElement(FlexBox, { flexDirection: "column", alignItems: "center", className: styles.root },
        React.createElement(FlexBox, { className: styles.main },
            React.createElement(Typography, { className: styles.title, variant: "subtitle2" }, title),
            React.createElement(GameFieldOnlineList, { votedUsers: votedUsers })),
        isVotingStarted ?
            React.createElement(GameFieldControls, { cards: cards, isVotingStarted: isVotingStarted, storieId: votingStorieId, votedCard: votedScore }) :
            React.createElement(GameFieldResults, { votedUsers: votedUsers, isVotingStarted: isVotingStarted })));
};
export default GameField;
