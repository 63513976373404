import React from 'react';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';
var useRedirectedPath = function () {
    var _a = useLocation(), pathname = _a.pathname, search = _a.search;
    var redirectedPath = React.useMemo(function () {
        if (search) {
            var redirect = QueryString.parse(search).redirect;
            return String(redirect);
        }
        return '/new-game';
    }, [search]);
    return pathname === '/' ? redirectedPath : pathname;
};
export default useRedirectedPath;
