import React from 'react';
import { useQuery } from '@apollo/client';
import { Header } from 'containers';
import { useGameIdSelector } from 'hooks';
import { FlexBox } from 'components';
import GameInfoQuery from './GameInfoQuery.graphql';
import GameStoriesQuery from './GameStories/GameStoriesQuery.graphql';
import GameStories from './GameStories';
import GameField from './GameField';
import useGameSubscriptions from './useGameSubscriptions';
var Game = function () {
    useGameSubscriptions();
    var gameId = useGameIdSelector();
    var _a = useQuery(GameInfoQuery, {
        variables: {
            gameId: gameId,
        },
    }), _b = _a.data, _c = _b === void 0 ? {} : _b, _d = _c.gameInfo, gameInfo = _d === void 0 ? {} : _d, loading = _a.loading;
    var _e = useQuery(GameStoriesQuery, {
        variables: {
            gameId: gameId,
        },
    }), _f = _e.data, _g = _f === void 0 ? {} : _f, _h = _g.stories, stories = _h === void 0 ? [] : _h, storiesLoading = _e.loading;
    var isGameOwner = gameInfo.isGameOwner, _j = gameInfo.status, _k = _j === void 0 ? {} : _j, _l = _k.votingStorieId, votingStorieId = _l === void 0 ? null : _l, _m = _k.isVotingStarted, isVotingStarted = _m === void 0 ? false : _m;
    var activeStorieTitle = React.useMemo(function () {
        var activeStorie = stories.find(function (storie) { return storie.id === votingStorieId; });
        return activeStorie ? activeStorie.storieName : 'Waiting for the start..';
    }, [JSON.stringify(stories), votingStorieId]);
    if (loading || storiesLoading) {
        return null;
    }
    return (React.createElement(FlexBox, { flexDirection: "column", alignItems: "center", height: "100vh" },
        React.createElement(Header, null),
        React.createElement(FlexBox, { width: "100%", height: "100%" },
            React.createElement(GameField, { title: activeStorieTitle }),
            React.createElement(GameStories, { isGameOwner: isGameOwner, currentVotingStorie: votingStorieId, isVotingStarted: isVotingStarted }))));
};
export default Game;
