import { makeStyles } from '@material-ui/core';
export var textFieldStyles = makeStyles({
    container: {
        width: '100%',
        height: 90,
        '& p': {
            marginLeft: 0,
        },
    },
});
