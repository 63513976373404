import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { useGameIdSelector } from 'hooks';
import { FlexBox, Card } from 'components';
import { gameFieldOnlineListStyles } from './styles';
import GameFieldOnlineListQuery from './GameFieldOnlineListQuery.graphql';
var GameFieldOnlineList = function (_a) {
    var _b = _a.votedUsers, votedUsers = _b === void 0 ? [] : _b;
    var styles = gameFieldOnlineListStyles();
    var gameId = useGameIdSelector();
    var _c = useQuery(GameFieldOnlineListQuery, {
        variables: {
            gameId: gameId,
        },
    }).data, _d = _c === void 0 ? {} : _c, _e = _d.onlineList, onlineList = _e === void 0 ? [] : _e;
    return (React.createElement(FlexBox, { className: styles.root }, onlineList.map(function (user) {
        var votedUser = votedUsers === null || votedUsers === void 0 ? void 0 : votedUsers.find(function (vote) { return vote.userId === user.id; });
        return (React.createElement(FlexBox, { key: user.id, flexDirection: "column", className: styles.userCard },
            React.createElement(Card, { selected: Boolean(votedUser), disabled: true }, votedUser === null || votedUser === void 0 ? void 0 : votedUser.value),
            React.createElement(Typography, { variant: "caption", className: styles.username }, user.username)));
    })));
};
export default GameFieldOnlineList;
