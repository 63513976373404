var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeReference, useApolloClient, useLazyQuery, useSubscription } from '@apollo/client';
import { debounce } from 'lodash-es';
import { useGameIdSelector } from 'hooks';
import MeQuery from 'containers/Auth/MeQuery.graphql';
import GameStatusChangedSubscription from './GameStatusChangedSubscription.graphql';
import GameInfoQuery from './GameInfoQuery.graphql';
import GameVotesUpdated from './GameVotesUpdated.graphql';
import GameUpdateOnlineListSubscription from './GameUpdateOnlineListSubscription.graphql';
var useGameSubscriptions = function () {
    var client = useApolloClient();
    var gameId = useGameIdSelector();
    var refreshGameInfo = useLazyQuery(GameInfoQuery, {
        variables: {
            gameId: gameId,
        },
        fetchPolicy: 'network-only',
    })[0];
    useSubscription(GameStatusChangedSubscription, {
        variables: {
            gameId: gameId,
        },
        onSubscriptionData: function (_a) {
            var status = _a.subscriptionData.data.gameStatusChanged;
            client.cache.modify({
                id: client.cache.identify(makeReference('ROOT_QUERY')),
                fields: {
                    gameInfo: function (existing) {
                        var votedScore = status.isVotingStarted ? existing.votedScore : null;
                        var gameInfo = __assign(__assign({}, existing), { votedScore: votedScore, status: status });
                        return gameInfo;
                    },
                },
                optimistic: true,
            });
            client.cache.modify({
                id: "Storie:" + status.votingStorieId,
                fields: {
                    isVoted: function () {
                        return !status.isVotingStarted;
                    },
                },
                optimistic: true,
            });
        },
    });
    useSubscription(GameUpdateOnlineListSubscription, {
        variables: {
            gameId: gameId,
        },
        onSubscriptionData: function (_a) {
            var updateOnlineList = _a.subscriptionData.data.updateOnlineList;
            var _b = client.readQuery({
                query: MeQuery,
            }).me, _c = _b === void 0 ? {} : _b, _d = _c.id, id = _d === void 0 ? null : _d;
            var isMeOnline = updateOnlineList.find(function (user) { return user.id === id; });
            if (!isMeOnline) {
                debounce(refreshGameInfo, 1000)();
            }
            client.cache.modify({
                id: client.cache.identify(makeReference('ROOT_QUERY')),
                fields: {
                    onlineList: function () {
                        return updateOnlineList;
                    },
                },
                optimistic: true,
            });
            client.cache.gc();
        },
    });
    useSubscription(GameVotesUpdated, {
        variables: {
            gameId: gameId,
        },
        onSubscriptionData: function (_a) {
            var updateUserVotes = _a.subscriptionData.data.updateUserVotes;
            client.cache.modify({
                id: client.cache.identify(makeReference('ROOT_QUERY')),
                fields: {
                    gameInfo: function (existing) {
                        var gameInfo = __assign(__assign({}, existing), { status: __assign(__assign({}, existing.status), { votedUsers: updateUserVotes }) });
                        return gameInfo;
                    },
                },
                optimistic: true,
            });
        },
    });
};
export default useGameSubscriptions;
