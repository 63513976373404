var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { TextField as MaterialTextField, Box, Button, Container, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Typography, } from '@material-ui/core';
import { TextField, Select, CheckBox } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { newGameStyles } from './styles';
import { newGameValidationSchema } from './validation';
import { DashboardStruct } from '../dashboard';
import NewGameMutation from './NewGameMutation.graphql';
;
;
var INITIAL_VALUES = {
    gameName: '',
    votingSystem: 'fibonacci',
    allowSpectators: false,
    stories: [],
};
var NewGame = function () {
    var styles = newGameStyles();
    var history = useHistory();
    var _a = React.useState([]), stories = _a[0], setStory = _a[1];
    var storieRef = React.useRef();
    var _b = useForm({
        mode: 'onChange',
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(newGameValidationSchema),
    }), handleSubmit = _b.handleSubmit, control = _b.control, setValue = _b.setValue, isValid = _b.formState.isValid;
    var handleUsAdd = React.useCallback(function (e) {
        if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
            e.preventDefault();
            if (!storieRef.current.value.trim()) {
                return;
            }
            setStory(function (prev) { return __spreadArray(__spreadArray([], prev), [{
                    id: uuid(),
                    value: storieRef.current.value,
                }]); });
        }
    }, []);
    var handleUsRemove = React.useCallback(function (id) {
        setStory(stories.filter(function (us) { return us.id !== id; }));
    }, [stories]);
    var _c = useMutation(NewGameMutation, {
        onCompleted: function (_a) {
            var id = _a.createGame.id;
            history.push("/game/" + id);
        },
    }), createGame = _c[0], createGameLoading = _c[1].loading;
    var onSubmit = function (values) { return createGame({ variables: { data: values } }); };
    React.useEffect(function () {
        setValue('stories', stories.map(function (storie) { return storie.value; }), { shouldValidate: true });
        storieRef.current.value = '';
    }, [JSON.stringify(stories)]);
    return (React.createElement(DashboardStruct, null,
        React.createElement(Container, { maxWidth: "sm", component: "form", onSubmit: handleSubmit(onSubmit), className: styles.container },
            React.createElement(Box, { paddingBottom: 3 },
                React.createElement(Typography, { variant: "h5", align: "center" }, "Game options")),
            React.createElement(TextField, { name: "gameName", label: "Game name", control: control, fullWidth: true, variant: "outlined" }),
            React.createElement(FormControl, { variant: "outlined", fullWidth: true },
                React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "Voting system"),
                React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", label: "Voting system", name: "votingSystem", control: control },
                    React.createElement(MenuItem, { value: "fibonacci" }, "Fibonacci ( 0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ? )"),
                    React.createElement(MenuItem, { value: "almostFibonacci" }, "Almost Fibonacci (0, \u00BD, 1, 2, 3, 5, 8, 13, 20, 40, 100, ?)"),
                    React.createElement(MenuItem, { value: "x2" }, "x2 (0, 1, 2, 4, 8, 16, 32, 64, ?)"),
                    React.createElement(MenuItem, { value: "from0to10" }, "From 0 to 10 (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ?)"))),
            React.createElement(Box, { paddingTop: 2 },
                React.createElement(FormControlLabel, { value: "end", control: React.createElement(CheckBox, { color: "primary", name: "allowSpectators", control: control }), label: "Allow to join as spectator", labelPlacement: "end" })),
            React.createElement(Box, { paddingY: 2 },
                React.createElement(List, { className: styles.list },
                    React.createElement(Box, { className: styles.listItems }, stories.map(function (_a) {
                        var id = _a.id, value = _a.value;
                        return (React.createElement(ListItem, { key: id, dense: true },
                            React.createElement(ListItemText, { primary: value }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(IconButton, { size: "small", onClick: function () { return handleUsRemove(id); } },
                                    React.createElement(RemoveIcon, { fontSize: "small" })))));
                    })),
                    React.createElement(ListItem, { dense: true },
                        React.createElement(MaterialTextField, { label: "Type user story", fullWidth: true, onKeyDown: handleUsAdd, inputRef: storieRef, InputProps: { endAdornment: (React.createElement(IconButton, { size: "small", onClick: handleUsAdd, edge: "end" },
                                    React.createElement(AddCircle, { fontSize: "small" }))),
                            } })))),
            React.createElement(Button, { variant: "contained", color: "primary", type: "submit", disabled: !isValid || createGameLoading, fullWidth: true }, "Start game"))));
};
export default NewGame;
